import React, { useContext } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import context from '../context';

export default ({ children, to })=> {
  const state = useContext(context);

  return(
    <AniLink paintDrip hex={state.primaryColor} to={to} duration={.5}>
      {children}
    </AniLink>    
  )
}
